import React from "react";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { useAlternateLangs } from "../../components/Hreflangs";
import H from "../../components/Headline";

const breadCrumbLevels = {
  Hjem: "/",
  "Personvernregler": "no/privacy-policy"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/privacy-policy"
);

const PrivacyPolicy = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Personvernregler"
        description="Detaljer om min personvernpolicy som forklarer hvordan tjenestene mine fungerer og hvordan beskyttelsen av dine personlige data er garantert."
        lang="no"
        alternateLangs={alternateLangs}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <MainContent article>
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

        <H as="h1">Databeskyttelse</H>
        <p>Personvernpolicy</p>
        <p>
          Nedenfor vil jeg forklare hvilken data jeg samler inn, behandler og bruker, når og for hvilket formål. Målet er å
          forklare deg hvordan mine tjenester fungerer, og hvordan beskyttelsen av dine personlige data er garantert i
          denne sammenheng.
        </p>
        <p>Jeg samler inn, behandler og bruker kun personlige data der du har gitt ditt samtykke eller lovgivning tillater det.</p>
        <p>
          Denne personvernpolicyen kan lastes ned, lagres og skrives ut når som helst via URL
          https://matthiaskupperschmidt.com/privacy-policy/.
        </p>
        <H as="h2">Kontrollerens navn og adresse</H>
        <p>Kontroller i henhold til personvernlovgivningen er:</p>
        <p>Matthias Kupperschmidt</p>
        <p>Frederikssundsvej 22</p>
        <p>2400 København, Danmark</p>
        <p>Du kan kontakte meg når som helst via e-post på legal@matthiaskupperschmidt.com.</p>
        <H as="h2">Navn og adresse til personvernansvarlig</H>
        <p>Kontaktinformasjon til personvernansvarlig:</p>
        <p>Matthias Kupperschmidt</p>
        <p>Frederikssundsvej 22</p>
        <p>2400 København, Danmark</p>
        <p>Du kan kontakte meg når som helst via e-post på legal@matthiaskupperschmidt.com.</p>
        <H as="h2">Generelle punkter om databeskyttelse</H>
        <p>Omfanget av behandling av personopplysninger</p>
        <p>
          Jeg behandler kun brukernes personopplysninger der det er nødvendig for å tilby en operativ nettside samt innhold
          og tjenester. Som regel behandles brukernes personopplysninger kun etter at brukeren har gitt sitt samtykke. Unntak
          gjelder i slike tilfeller der samtykke ikke kan innhentes på forhånd av faktiske grunner, og behandling av
          opplysningene er tillatt ved lov.
        </p>
        <H as="h2">Retslig grunnlag for behandling av personopplysninger</H>
        <p>
          Artikkel 6 (1, a) i EU's Generelle Personvernforordning (GDPR) tjener som rettslig grunnlag der jeg innhenter
          den registrertes samtykke til behandling av personopplysninger.
        </p>
        <p>
          Artikkel 6 (1, b) GDPR tjener som rettslig grunnlag der behandling av personopplysninger er nødvendig for å
          utføre en kontrakt der den registrerte er part i kontrakten. Dette gjelder også for behandling som er nødvendig
          for å gjennomføre tiltak før kontrakten inngås.
        </p>
        <p>
          Artikkel 6 (1, c) GDPR tjener som rettslig grunnlag der behandling av personopplysninger er nødvendig for å
          utføre en rettslig forpliktelse som mitt selskap er underlagt.
        </p>
        <p>
          Der behandlingen er nødvendig for å ivareta en legitim interesse for mitt selskap eller en tredjepart, og
          den registrertes interesser og grunnleggende rettigheter og friheter ikke oppveier den førstnevnte
          interessen, tjener Artikkel 6 (1, f) GDPR som rettslig grunnlag for behandlingen.
        </p>

        <H as="h2">Sletting og lagringsperiode av data</H>
        <p>
          Den registrertes personopplysninger blir slettet eller blokkert så snart formålet med lagringen opphører.
          Lagring kan også forekomme dersom det er bestemt av europeiske eller nasjonale lovgivere i forskrifter, lover
          eller andre bestemmelser i EU-retten som Kontrolleren er underlagt. Data blir også slettet eller fjernet dersom
          en lagringsperiode foreskrevet av nevnte lovgivning er utløpt, med mindre det er krav om fortsatt lagring
          av data for inngåelse eller utførelse av en kontrakt.
        </p>
        <p>Typer behandlet data:</p>
        <p>– Kontaktinformasjon (f.eks. e-post, navn).</p>
        <p>– Bruksdata (f.eks., besøkte sider, interesse for innhold, tilgangstider).</p>
        <p>– Meta/kommunikasjonsdata (f.eks. enhetsinformasjon, IP-adresser).</p>
        <H as="h2">Kategorier av registrerte</H>
        <p>Besøkende til og brukere av nettjenesten, samlet kalt “brukere” heretter.</p>
        <H as="h2">Formål med behandlingen</H>
        <p>– Tilby nettjenesten, dens funksjoner og innhold.</p>
        <p>– Besvare kontaktforespørsler og kommunikasjon med brukere.</p>
        <p>– Sikkerhetstiltak.</p>
        <p>– Målgruppeanalyse/markedsføring</p>
        <H as="h3">Brukte definisjoner</H>
        <p>
          ‘Personopplysninger’ betyr all informasjon som kan knyttes til en identifisert eller identifiserbar fysisk person (‘den registrerte’); en identifiserbar fysisk person er en som kan identifiseres, direkte eller indirekte, spesielt ved henvisning til en identifikator som et navn, et identifikasjonsnummer, stedsdata, en online identifikator (f.eks. cookie) eller til en eller flere faktorer som er spesifikke for den fysiske, fysiologiske, genetiske, mentale, økonomiske, kulturelle eller sosiale identiteten til den fysiske personen.
        </p>
        <p>
          ‘Behandling’ betyr enhver operasjon eller sett av operasjoner som utføres på personopplysninger eller på sett av
          personopplysninger, enten eller ikke ved automatiserte midler. Begrepet er vidtrekkende og dekker praktisk talt
          enhver håndtering av data.
        </p>
        <p>
          ‘Kontroller’ refererer til den fysiske eller juridiske personen, offentlige myndigheten, byrået eller andre
          organer som, alene eller sammen med andre, bestemmer formålene og midlene for behandlingen av personopplysninger.
        </p>
        <H as="h2">Relevante rettslige grunnlag</H>
        <p>
          I samsvar med Artikkel 13 GDPR informerer jeg deg om de rettslige grunnlagene for vår databehandling. Følgende
          gjelder der det rettslige grunnlaget ikke er nevnt i personvernpolicyen: Det rettslige grunnlaget for innhenting
          av samtykke er Artikkel 6 (1, a) og Artikkel 7 GDPR, det rettslige grunnlaget for behandling for å utføre våre
          tjenester og gjennomføre kontraktsmessige tiltak, samt besvare forespørsler er Artikkel 6 (1, b) GDPR, det rettslige
          grunnlaget for behandling for å oppfylle våre rettslige forpliktelser er Artikkel 6 (1, c) GDPR, og det rettslige
          grunnlaget for behandling for å ivareta våre legitime interesser er Artikkel 6 (1, f) GDPR.
        </p>
        <H as="h2">Samarbeid med behandlere og tredjeparter</H>
        <p>
          Der jeg, ved behandling, utleverer data til andre parter og selskaper (behandlere eller tredjeparter), eller gir
          direkte eller annen tilgang til dataene, skjer dette kun basert på lovbestemt tillatelse (f.eks. dersom overføring
          av data til tredjeparter, som en betalingstjenesteleverandør, er nødvendig for å utføre en kontrakt i samsvar med
          Artikkel 6 (1, b) GDPR, du har gitt samtykke, en lovbestemt forpliktelse er til stede, eller basert på våre
          legitime interesser (f.eks. når du bruker agenter, webhoteller osv.).
        </p>
        <p>
          Der jeg bestiller tredjeparter med behandling av data basert på en såkalt ‘behandlingsavtale’,
          skjer dette basert på Artikkel 28 GDPR.
        </p>
        <H as="h2">Overføring til tredjeland</H>
        <p>
          Der jeg behandler data i et tredjeland (dvs. utenfor EU eller EØS), eller dette skjer når brukt som en del av
          tredjepartstjenester eller utlevering eller, som kan gjelde, overføring av data til tredjeparter, skjer dette kun
          hvis nødvendig for å utføre våre (for)kontraktuelle plikter, basert på ditt samtykke, på grunn av en rettslig
          forpliktelse, eller basert på våre legitime interesser. Med forbehold om lovbestemte eller kontraktsmessige
          tillatelser, behandler jeg kun, eller ordner for data å bli behandlet, i et tredjeland der de spesifikke
          kravene i Artikkel 44 flg GDPR er til stede. Med andre ord, behandling skjer, for eksempel, basert på spesifikke
          garantier som den offisielt anerkjente fastsettelsen av databeskyttelsesnivået som tilsvarer EU (f.eks. for USA
          av ‘Privacy Shield’) eller overholdelse av offisielt anerkjente spesifikke forpliktelser (såkalte ‘standard
          kontraktsklausuler’).
        </p>
        <H as="h2">Rettigheter til registrerte</H>
        <p>
          Du har rett til å kreve bekreftelse på om de aktuelle dataene behandles og for informasjon om disse dataene, samt
          ytterligere informasjon og en kopi av dataene i samsvar med Artikkel 15 GDPR.
        </p>
        <p>
          I samsvar med Artikkel 16 GDPR har du rett til å kreve at data om deg blir fullført, eller uriktige data om deg
          korrigeres.
        </p>
        <p>
          I samsvar med Artikkel 17 GDPR har du rett til å kreve sletting av data om deg uten unødig forsinkelse eller,
          alternativt i samsvar med Artikkel 18 GDPR, begrenset behandling av dataene.
        </p>
        <p>
          Du har rett til å kreve mottak av data som du har gitt meg i samsvar med Artikkel 20 GDPR, og å kreve overføring
          til en annen kontroller.
        </p>
        <p>
          Du har også rett til i samsvar med Artikkel 77 GDPR å inngi klage til den relevante tilsynsmyndigheten.
        </p>
        <H as="h2">Tilbakekalling</H>
        <p>Du har rett til å tilbakekalle samtykke gitt i samsvar med Artikkel 7 (3) GDPR med fremtidig virkning.</p>
        <H as="h2">Rett til å protestere</H>
        <p>
          I samsvar med Artikkel 21 GDPR kan du protestere mot fremtidig behandling av data om deg. Spesielt kan protesten
          være mot behandling for formål med direkte reklame.
        </p>
        <H as="h2">Informasjonskapsler og retten til å protestere mot direkte reklame</H>
        <p>
          Informasjonskapsler refererer til små filer som lagres på brukernes datamaskiner. Ulike detaljer kan lagres i informasjonskapslene. En informasjonskapsel tjener primært til å lagre detaljer om en bruker (eller, som kan gjelde, enheten som informasjonskapselen er lagret på) under eller etter besøket innenfor et nettjenestetilbud. Informasjonskapsler som slettes etter at en bruker forlater et nettjenestetilbud og lukker nettleseren, kalles midlertidige informasjonskapsler eller, som kan gjelde, økt-informasjonskapsler. For eksempel kan en innloggingsstatus lagres i en slik informasjonskapsel. Informasjonskapsler som forblir selv etter at nettleseren er lukket, kalles permanente eller vedvarende. For eksempel tillater dette at innloggingsstatusen lagres hvis brukeren besøker nettstedet etter flere dager. Brukernes interesser for måling av publikum eller markedsføringsformål kan også lagres i en slik informasjonskapsel. Informasjonskapsler som tilbys av andre leverandører enn den kontrolleren som driver nettjenestetilbudet, kalles tredjeparts informasjonskapsler (ellers første parts informasjonskapsler hvis bare dens informasjonskapsler finnes).
        </p>
        <p>Jeg kan bruke midlertidige og permanente informasjonskapsler, og forklare dette i vår personvernpolicy.</p>
        <p>
          Hvis brukere ikke ønsker at informasjonskapsler skal lagres på datamaskinen deres, bes de om å deaktivere
          den tilsvarende innstillingen i systeminnstillingene. Lagrede informasjonskapsler kan slettes i nettleserens systeminnstillinger. Ekskludering av informasjonskapsler kan føre til begrenset funksjon av dette nettjenestetilbudet.
        </p>
        <p>
          En generell innvending mot bruk av informasjonskapsler plassert for formål med online markedsføring kan erklæres med en rekke tjenester, fremfor alt i tilfelle sporing, via USAs nettsted http://www.aboutads.info/choices/ eller EUs nettsted http://www.youronlinechoices.com/. I tillegg kan lagring av informasjonskapsler oppnås ved å deaktivere dem i nettleserens innstillinger. Vær oppmerksom på at ikke alle funksjonene i dette nettjenestetilbudet da kan brukes.
        </p>
        <p>
          Når du får tilgang til nettstedet vårt, blir brukerne informert om bruken av informasjonskapsler for analyseformål ved en informasjonsbanner som henviser til personvernpolicyen. I denne forbindelse er det også en henvisning til hvordan lagring av informasjonskapsler kan forhindres i nettleserinnstillingene. Dette såkalte informasjonskapselsporingsfilteret hjelper brukeren med å lagre hvilke informasjonskapsler som skal settes. Hans/hennes egen informasjonskapsel lagres for dette formålet. Hvis du sletter informasjonskapslene dine, bør du ikke slette denne informasjonskapselen, da informasjonskapselsporingsfilteret ellers ikke kan gjenkjenne preferansene dine.
        </p>
        <H as="h2">Sletting av data</H>
        <p>
          Data behandlet av meg slettes i samsvar med artiklene 17 og 18 GDPR, eller begrenses i sin behandling.
          Med mindre det uttrykkelig er angitt som en del av personvernpolicyen, slettes data lagret hos meg så snart det
          ikke er nødvendig for sitt formål, og ingen lovbestemte plikter for oppbevaring står i veien for sletting. Hvor
          data ikke slettes fordi det er nødvendig for andre lovbestemte formål tillatt ved lov, begrenses behandlingen.
          Med andre ord, data er blokkert og ikke behandlet for andre formål. For eksempel gjelder dette data som må beholdes
          under kommersiell eller skatterett.
        </p>
        <H as="h2">Forretningsrelatert behandling</H>
        <p>Jeg behandler også</p>
        <p>– kontraktsdata (f.eks. kontraktsobjekt, varighet, kundekategori).</p>
        <p>– betalingsdata (f.eks. bankopplysninger, betalingshistorikk)</p>
        <p>
          av mine kunder, potensielle kunder og forretningspartnere for å tilby kontraktsmessige tjenester,
          service og kundebehandling, markedsføring, reklame og markedsundersøkelser.
        </p>
        <H as="h2">Hosting</H>
        <p>
          Tjenestene for hosting brukt av meg tjener til å tilby følgende tjenester: Infrastruktur- og plattformtjenester,
          datakapasitet, minneplass og databasetjenester, og sikkerhetstjenester samt tekniske vedlikeholdstjenester som jeg
          bruker for å drifte dette nettjenestetilbudet.
        </p>
        <p>
          På denne måten, meg eller, som kan gjelde, min hosting-leverandør behandler inventardata, kontaktinformasjon, innhold
          data, kontraktsdata, bruksdata, meta- og kommunikasjonsdata fra kunder og potensielle kunder av og besøkende til
          dette nettjenestetilbudet basert på våre legitime interesser i effektivt og sikkert å tilby dette nettjenestetilbudet
          i samsvar med artikkel 6 (1, f) GDPR i forbindelse med artikkel 28 GDPR (inngåelse av en behandlingsavtale).
        </p>
        <H as="h2">Innsamling av tilgangsdata og loggfiler</H>
        <p>
          Basert på min legitime interesse i henhold til artikkel 6 (1, f) GDPR, jeg eller, som kan gjelde, min hosting-leverandør
          samler data om enhver tilgang til serveren der denne tjenesten er plassert (såkalte serverloggfiler). Tilgangsdata inkluderer navnet på nettstedet som er besøkt, fil, dato og tid for tilgang, mengden data overført, rapport om vellykket tilgang, nettlesertype sammen med versjon, brukerens operativsystem, henvisnings-URL (tidligere besøkt nettsted), IP-adresse og forespørsel fra leverandør.
        </p>
        <p>
          Midlertidig lagring av IP-adressen av systemet er nødvendig for å muliggjøre levering av nettstedet til
          brukerens datamaskin. Brukerens IP-adresse må forbli lagret i varigheten av økten for å tillate dette.
        </p>
        <p>
          Lagring finner sted i loggfiler for å sikre funksjonaliteten til nettstedet, spesielt for å analysere et
          angrep (DOS-angrep) og misbruk av verktøyene som tilbys. Dataene tjener også til å optimalisere nettstedet og
          sikre sikkerheten til mine IT-systemer. Dataene blir ikke evaluert for markedsføringsformål i denne forbindelse.
        </p>
        <p>
          Dataene slettes så snart de ikke lenger er nødvendige for å oppnå formålet med deres innsamling. Dette er
          generelt tilfelle etter 30 dager.
        </p>
        <p>
          Innsamling av data for å tilby nettstedet og lagring av data i loggfiler er obligatorisk for
          drift av nettstedet. Som et resultat, er brukeren ikke i stand til å protestere.
        </p>
        <H as="h2">Tilbyr kontraktstjenester</H>
        <p>
          Jeg behandler inventardata (f.eks. navn og adresser samt kontaktinformasjon for kunder) og kontraktsdata (f.eks.
          tjenester brukt, navn på kontaktpunkter, betalingsinformasjon) for å utføre mine kontraktsmessige forpliktelser og
          tjenester i samsvar med artikkel 6 (1, b) GDPR. Oppføringer merket som obligatoriske i online skjemaer er nødvendige
          for inngåelse av kontrakten.
        </p>
        <p>
          Når du bruker mine nettjenester, lagrer jeg IP-adressen og tidspunktet for den respektive brukeraktiviteten.
          Lagring skjer basert på mine legitime interesser, også for å beskytte brukeren mot misbruk og annen uautorisert
          bruk. Disse dataene blir i prinsippet ikke utlevert til tredjeparter, med mindre det er nødvendig for å følge
          opp mine krav eller det er en lovbestemt forpliktelse i denne forbindelse i samsvar med artikkel 6 (1, c) GDPR.
        </p>
        <p>
          Jeg behandler bruksdata (f.eks. sider besøkt av mitt nettjenestetilbud, interesse for mine produkter) og
          innholdsdata (f.eks. oppføringer i kontaktskjemaet eller brukerprofilen) for reklameformål i en brukerprofil for
          å vise brukere, for eksempel, produktinformasjon basert på tjenester som tidligere er benyttet av dem.
        </p>
        <p>
          Dataene slettes ved utløp av lovbestemte garantier og lignende plikter, og kravet om å beholde dataene blir
          sjekket hvert tredje år. I tilfelle lovbestemte arkiveringsplikter, slettes dataene ved utløpet. Detaljer i
          en eventuell kundekonto forblir til den slettes.
        </p>
        <H as="h2">Administrasjon, regnskap, kontororganisasjon, kontaktadministrasjon</H>
        <p>
          Jeg behandler data som en del av administrative oppgaver samt organiseringen av min virksomhet, regnskap og
          overholdelse av lovbestemte plikter som arkivering. Som sådan behandler jeg de samme dataene som jeg behandler
          når jeg tilbyr mine kontraktstjenester. Grunnlagene for behandling er artikkel 6 (1, c) GDPR og artikkel 6 (1, f)
          GDPR. Kunder, potensielle kunder, forretningspartnere og besøkende på nettstedet påvirkes av behandlingen.
          Formålet og interessen i behandlingen ligger i administrasjon, regnskap, kontororganisasjon og arkivering av data,
          dvs. oppgaver som tjener til å opprettholde min forretningsaktivitet, utføre mine oppgaver og tilby mine tjenester.
          Slettingen av data med hensyn til kontraktstjenester og kommunikasjon tilsvarer detaljene angitt med disse
          behandlingsaktivitetene.
        </p>
        <p>
          Som sådan utleverer eller overfører jeg data til Skattekontoret, rådgivere som skatterådgivere eller revisorer,
          og andre oppkrevere og betalingstjenesteleverandører.
        </p>
        <p>
          I tillegg, basert på mine kommersielle interesser, lagrer jeg detaljer om leverandører, arrangører og andre
          forretningspartnere, for eksempel for å kunne kontakte dem senere. I prinsippet deler jeg disse dataene som
          hovedregel på permanent basis.
        </p>
        <H as="h2">Forretningsanalyse og markedsundersøkelser</H>
        <p>
          For å kunne drive min virksomhet kommersielt og identifisere markedstrender, og kunders og brukeres ønsker,
          analyserer jeg data tilgjengelig for meg om forretningsprosesser, kontrakter, forespørsler osv. Ved å gjøre dette,
          behandler jeg inventardata, kommunikasjonsdata, kontraktsdata, betalingsdata, bruksdata og metadata basert
          på artikkel 6 (1, f) GDPR, hvor datasubjekter inkluderer kunder, potensielle kunder, forretningspartnere og
          besøkende til og brukere av nettjenestetilbudet.
        </p>
        <p>
          Analysen utføres for å kunne evaluere virksomheten, markedsføring og markedsundersøkelser. Dette lar meg
          vurdere profilene til registrerte brukere med detaljer som deres kjøpsprosesser. Analysen tjener meg til å
          øke brukervennligheten og til å optimalisere mitt tilbud og forretningsmessig effektivitet. Analysen tjener
          meg alene og deles ikke eksternt, med mindre det innebærer anonymisert analyse med oppsummerte verdier.
        </p>
        <p>
          Hvor denne analysen eller profilene er personlige, blir de slettet eller anonymisert ved avslutning av brukeren,
          ellers etter to år fra avslutning av kontrakten. I tillegg produseres generell kommersiell analyse og generell
          bestemmelse av trender der det er mulig.
        </p>
        <H as="h2">E-post påmeldingsfunksjon</H>
        <p>
          Brukere har muligheten til å abonnere på innholdet mitt med e-posten sin. Brukere får de nødvendige
          obligatoriske detaljene ved registrering. Detaljer innputt ved registrering brukes til å dra nytte av tilbudet.
          Brukere kan motta e-poster relatert til tilbud eller endringer i omfanget av tilbudet eller tekniske forhold.
          Hvis brukere har sagt opp brukerkontoen sin, slettes dataene deres med hensyn til brukerkontoen, med forbehold
          om at oppbevaring er nødvendig av kommersielle eller skattemessige årsaker i samsvar med artikkel 6 (1, c) GDPR.
          Det er opp til brukerne å sikre dataene sine før slutten av kontrakten i tilfelle avbestilling. Jeg har rett til
          å ugjenkallelig slette alle brukerens data lagret under kontrakten.
        </p>
        <p>
          Når mine registrerings- og påloggingsfunksjoner brukes, samt når brukerkontoen brukes, lagrer jeg
          IP-adressen og tidspunktet for den respektive brukeraktiviteten. Lagring skjer ikke bare basert på mine
          legitime interesser, men også brukeren for å beskytte mot misbruk og annen uautorisert bruk. Disse dataene
          blir i prinsippet ikke utlevert til tredjeparter med mindre det er nødvendig for å følge opp mine krav eller
          det er en lovbestemt forpliktelse i denne forbindelse i samsvar med artikkel 6 (1, c) GDPR.
        </p>
        <H as="h2">Kontakt</H>
        <p>
          Når du kontakter meg (for eksempel ved hjelp av kontaktskjemaet, e-post, telefon eller via sosiale medier), blir
          brukerens detaljer behandlet for å håndtere kontaktforespørselen og i samsvar med artikkel 6 (1, b) GDPR.
          Brukerens detaljer kan lagres i et kundeadministrasjonssystem (CRM-system) eller et sammenlignbart
          forespørselshåndteringssystem.
        </p>
        <p>
          Jeg sletter forespørslene så snart de ikke lenger er nødvendige. Jeg vurderer kravet hvert andre år, og
          lovbestemte arkiveringsplikter gjelder også.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);
export default PrivacyPolicy;
